import { ReactElement } from "react";
import { BadgeSets, BttvEmote, JsonChatMsg, Video } from "../../redux/reducers/video";
import { Settings } from "./settings";
import { DateTime } from "luxon";

export class ChatMsg {
  readonly type: string;
  readonly displayName: string;
  readonly message: string;
  readonly color: string;
  readonly ts: Date;
  readonly emotes: Emote[];
  readonly badges: Badges;
  readonly systemMessage: string;
  constructor(type: string, displayName: string, message: string, color: string, ts: Date, emotes: Emote[], badges: Badges, systemMessage: string) {
    this.type = type;
    this.displayName = displayName;
    this.message = message;
    this.color = color;
    this.ts = ts;
    this.emotes = emotes;
    this.badges = badges;
    this.systemMessage = systemMessage;
  }

  static fromJson(msg: JsonChatMsg) {
    return new ChatMsg(
      msg.type,
      msg.display_name,
      msg.message,
      msg.color,
      new Date(msg.datetime),
      ChatMsg.parseEmotes(msg.emotes),
      ChatMsg.parseBadges(msg.badges),
      msg.system_message || "",
    );
  }

  static parseEmotes(emotes: string): Emote[] {
    if (emotes === "") {
      return [];
    }
    if (emotes === undefined) {
      console.log("huh, undefined emotes")
      return [];
    }

    return emotes.split("/").map(s => ChatMsg.parseEmote(s))
  }

  static parseEmote(emote: String): Emote {
    let [id, positions] = emote.split(":")

    return new Emote(
      id,
      positions.split(",").map(function(pos: string) {
        let [start, end] = pos.split("-").map(x => parseInt(x, 10))
        return { pos, start, end }
      })
    )
  }

  static parseBadges(s: string): Badges {
    let badges = new Badges()
    if (s === undefined || s === "") {
      return badges;
    }

    s.split(",").forEach(b => {
      let [set_id, version] = b.split("/");
      badges.push({ set_id, version });
    });

    return badges;
  }

  postedBefore(other: Date): boolean {
    return this.ts < other;
  }

  isAction(): boolean {
    return this.message.startsWith("\u0001ACTION");
  }

  getLocalTimeAsString(): string {
    const hours = String(this.ts.getHours()).padStart(2, '0');
    const minutes = String(this.ts.getMinutes()).padStart(2, '0');
    const seconds = String(this.ts.getSeconds()).padStart(2, '0');

    return '[' + hours + ':' + minutes + ':' + seconds + ']';
  }

  render(key: number, video: Video, settings: Settings): ReactElement {
    const { badgeSets, bttv } = video;
    let text = this.message;
    if (this.isAction()) {
      text = text.substring(8, this.message.length - 1);
    }

    // deal with unicode characters, not with code points
    let chars = [...text];

    let offset = 0;
    let textParts: (ReactElement|string)[] = [];
    this.emotes.forEach(function(emote: Emote, index: number) {
      emote.positions.forEach(function(pos, index2: number) {
        if (offset < pos.start) {
          textParts.push(chars.slice(offset, pos.start).join(""))
        }

        const emoteName = chars.slice(pos.start, pos.end + 1).join("")
        textParts.push(
          <span className="icon is-16x16" key={`emote-${index}-${index2}`}>
            <img src={`https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/default/dark/1.0`} alt={emoteName} title={emoteName} />
          </span>
        );

        offset = pos.end + 1
      });
    });

    if (offset < text.length) {
      textParts.push(chars.slice(offset).join(""))
    }

    // const bttvUrl = `https://cdn.betterttv.net/emote/5ba6d5ba6ee0c23989d52b10/3x.webp`
    textParts = textParts.map(function (part: ReactElement|string, index: number): string|ReactElement {
      if (typeof part != "string") {
        return part
      }

      let words: (ReactElement|string)[] = [];
      words = part.split(" ").map(function(word: string, wordIndex: number): string|ReactElement {
        var replacedWord: string|ReactElement = word + " ";
        var foundEmote = false

        if (word.match('^https?://')) {
          return <><a href={word}>{word}</a>&nbsp;</>;
        }

        // TODO Cheermotes
        //
        // // TODO Cheermotes

        bttv.channelEmotes.forEach(function (emote: BttvEmote) {
          if (emote.code === word) {
            replacedWord = <span className="icon is-16x16" key={`bttv-emote-${wordIndex}`}>
              <img src={`https://cdn.betterttv.net/emote/${emote.id}/3x.webp`} alt={word} title={word} />
            </span>
            foundEmote = true
          }
        })
        // minor optimisation, don't loop over emotes if we already found one
        if (!foundEmote) {
          bttv.sharedEmotes.forEach(function (emote: BttvEmote) {
            if (emote.code === word) {
              replacedWord = <span className="icon is-16x16" key={`bttv-emote-${wordIndex}`}>
                <img src={`https://cdn.betterttv.net/emote/${emote.id}/3x.webp`} alt={word} title={word} />
              </span>
            }
          })
        }
        return replacedWord;
      })
      return <span key={`p${index}`}>{words}</span>;
    });

    if (this.type === "USERNOTICE") {
      return <ChatEvent msgKey={key} msg={this} contents={textParts} video={video} settings={settings} />
    }

    const classNames = ["chat-msg", this.type]
    return <div className={classNames.join(" ")} data-raw={text} key={`msg-${key}`}>
      {settings.showTimestamps ? <MessageTime msg={this} relative={settings.relativeTimestamps} since={DateTime.fromISO(video.streamStartedAt)} /> : ''}
      <span>
        {this.badges.render(badgeSets)}
        <span style={{ color: this.color, marginRight: '3px' }}>{this.displayName}</span>
      </span>
      {textParts}
    </div>;
  }
}

type TextParts = (string|ReactElement)[]

const ChatEvent = (props: {msgKey: number, msg: ChatMsg, contents: TextParts, video: Video, settings: Settings}) => {
  const badgeSets = props.video.badgeSets;
  const systemMessage = props.msg.systemMessage.replaceAll('\\s', ' ');
  return <div className="chat-msg USERNOTICE" data-raw={props.msg.message} key={`msg-${props.msgKey}`} style={{borderLeftColor: props.msg.color}}>
      {props.settings.showTimestamps ? <MessageTime msg={props.msg} relative={props.settings.relativeTimestamps} since={DateTime.fromISO(props.video.streamStartedAt)} /> : ''}
      <span>
        {props.msg.badges.render(badgeSets)}
        <span style={{ color: props.msg.color, marginRight: '3px' }}>{systemMessage}</span>
      </span>
      {props.contents}
    </div>;
}

type MessageTimeProps = {
  msg: ChatMsg;
  relative: boolean;
  since: DateTime;
}
const MessageTime = (props: MessageTimeProps) => {
  let time = props.msg.getLocalTimeAsString();
  if (props.relative) {
    time = DateTime.fromJSDate(props.msg.ts).diff(props.since).toISOTime({suppressMilliseconds: true}) || '-1';
  }
  return <span className="msg-time">{time}</span>;
}

class Emote {
  readonly id: string;
  readonly positions: { pos: string, start: number, end: number }[]
  constructor(id: string, positions: { pos: string, start: number, end: number }[]) {
    this.id = id;
    this.positions = positions;
  }

  render(): ReactElement {
    return <>EMOTE</>;
  }
}

interface Badge {
  set_id: string;
  version: string;
}

class Badges extends Array<Badge> {
  render(badgeSets: BadgeSets): ReactElement {
    let badges: ReactElement[] = [];
    this.forEach(badge => {
      if (!badgeSets.hasOwnProperty(badge.set_id)) {
        console.warn('Unknown badge found: ' + badge.set_id)
        return
      }
      const set = badgeSets[badge.set_id];
      if (!set.versions.hasOwnProperty(badge.version)) {
        console.warn('Unknown version found for ' + badge.set_id + ': ' + badge.version)
        return
      }
      const version = set.versions[badge.version];

      badges.push(
        <span className="icon is-16x16" key={"badge-" + set.set_id + "/" + version.id}>
          <img src={version.image_url_1x} className="badge" title={version.title} alt={version.title} />
        </span>
      );
    });

    return <>{badges}</>;
  }
}
