import {React, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

const User = () => {
    const dispatch = useDispatch();
    const {user,isAuthenticated, getAccessTokenSilently} = useAuth0();

    const songlist = useSelector(state => state.songlist);

    useEffect(() => {
        // get settings
    }, [getAccessTokenSilently, dispatch]);

    return isAuthenticated && (
        <div>
            <h1 className={"is-size-1"}>User profile</h1>
        </div>
    );
}

export default User;
