import { createSlice } from '@reduxjs/toolkit';
import { syncUserWithBackend } from '../../api/auth.js';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    state: 'idle',
    user: null,
  },
  reducers: {
    userSynced(state, action) {
      state.state = 'idle'
      state.user = action.payload.user
    },
    syncing(state) {
      state.state = 'syncing'
    },
    failedToSyncUser(state) {
      state.state = 'failed'
      state.user = null
    }
  }
})

export const { userSynced, syncing, failedToSyncUser } = authSlice.actions;

export const syncUser = (user, token) => async dispatch => {
  window.setTimeout(async () => {
    const res = await syncUserWithBackend(user, token);
    dispatch(userSynced(res));
  }, 500); // delay to avoid token timing issues
}

export default authSlice.reducer
