import { useEffect, useState } from 'react';
import { Module, ModuleSetting, ModuleSettingType, UserModule, disableModule, enableModule, fetchModules, fetchUserModules } from "../redux/reducers/modules";
import classNames from 'classnames';
import { useAppSelector } from '../redux/hooks';
import { InputField } from '../lib/bulma-form/fields';
import { useDispatch } from 'react-redux';

const Dashboard = () => {
  const dispatch = useDispatch();
  const availableModules = useAppSelector(s => s.modules);

  useEffect(() => {
      dispatch(fetchModules());
      dispatch(fetchUserModules());
  }, [dispatch]);

  return <><h1 className="title">Dashboard</h1>
    <p>Currently available modules:</p>
    <Modules modules={availableModules.modules} userModules={availableModules.userModules} loading={availableModules.status} />
  </>;
}

const Modules = (props: {modules: Module[], userModules: UserModule[], loading: string}) => {

  return <div className="columns">
    {props.modules.map((m: Module) => {
      const enabled = props.userModules.filter((um: UserModule) => um.ModuleId === m.id && um.Enabled).length === 1
      return <ModuleComponent key={m.name} Enabled={enabled} Module={m} />;
    })}
  </div>;
}

type ModuleProps = {
  Module: Module
  Enabled: boolean
}
const ModuleComponent = (props: ModuleProps) => {
  const [shownPanel, showPanel] = useState('info');
  const [isBusy, setBusy] = useState(false);
  const dispatch = useDispatch();

  const onToggle = async (newToggleState: boolean) => {
    setBusy(true);
    if (newToggleState === props.Enabled) {
      setBusy(false);
      return
    }
    if (newToggleState) {
      dispatch(enableModule(props.Module.id))
    } else {
      dispatch(disableModule(props.Module.id))
    }

    setBusy(false);
  };

  return <div className="column is-one-third">
    <article className='panel is-dark is-info'>
      <div className="panel-heading">
        <div className="level">
          <div className="level-left">
            <span className="level-item">
              {props.Module.name}
            </span>
          </div>
          <div className="level-right">
            <div className="level-item"> <ActiveToggle Active={props.Enabled} OnToggle={onToggle} Busy={isBusy} />
            </div>
          </div>
        </div>
      </div>
      <p className="panel-tabs">
        <a className={classNames({'is-active': shownPanel === 'info'})} onClick={() => showPanel('info')}>Info</a>
        <a className={classNames({'is-active': shownPanel === 'settings', 'is-hidden': props.Module.settings === null})} onClick={() => showPanel('settings')}>Settings</a>
      </p>
      <div className="panel-block">
        <div className={classNames({'is-hidden': shownPanel !== 'info'})}>
          <p>{props.Module.description}</p>
        </div>
        <div className={classNames({'is-hidden': shownPanel !== 'settings'})}>
          <ModuleSettingsComponent Enabled={props.Enabled} Module={props.Module} Busy={isBusy} />
        </div>
      </div>
      </article>
    </div>;
}

const ModuleSettingsComponent = (props: ModuleProps & CanBeBusy) => {
  if (props.Module.settings === null || props.Module.settings.length === 0) {
    return <p><i>This module has no settings</i></p>;
  }
  return <form>
    {props.Module.settings.map(s => <ModuleSettingField key={props.Module.name} Setting={s} Busy={props.Busy} />)}
  </form>
}

const ModuleSettingField = (props: {Setting: ModuleSetting} & CanBeBusy) => {
  const s = props.Setting;
  switch (s.type) {
    case ModuleSettingType.TemplatedString:
      return <InputField Label={s.label} Placeholder={s.default} Busy={props.Busy} />;
    default:
      return <p>Field</p>;
  }
}

type ToggleProps = {
  Active: boolean
  OnToggle: (newState: boolean) => void
}
const ActiveToggle = (props: ToggleProps & CanBeBusy) => {
  return <div className="field has-addons">
    <span className="control">
      <button disabled={props.Busy} className={classNames('button', {'is-danger': !props.Active})} onClick={() => props.OnToggle(false)}>Off</button>
    </span>
    <span className="control">
      <button disabled={props.Busy} className={classNames('button', {'is-success': props.Active})} onClick={() => props.OnToggle(true)}>On</button>
    </span>
  </div>;
}

export { Dashboard };
