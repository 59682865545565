import { createSlice } from '@reduxjs/toolkit';
import { GetWebhooks, AddWebhook, DeleteWebhook } from '../../api/webhooks.js';

const webhooksSlice = createSlice({
  name: 'webhooks',
  initialState: {
    webhooks: [],
    status: 'idle',
    error: null
  },
  reducers: {
    webhooksLoading(state) {
      if (state.status === 'idle') {
        state.status = 'loading';
      }
    },
    webhooksLoaded(state, action) {
      if (state.status === 'loading') {
        state.status = 'idle';
        state.webhooks = action.payload;
      }
    },
    webhookAdded(state, action) {
      state.webhooks.push(action.payload);
    },
    webhookDeleted(state, action) {
      state.webhooks = state.webhooks.filter((wh) => { return wh.id !== action.payload; });
    }
  }
});

export const { webhooksLoading, webhooksLoaded, webhookAdded, webhookDeleted } = webhooksSlice.actions;

export const fetchWebhooks = (authToken) => async dispatch => {
  dispatch(webhooksLoading());
  const response = await GetWebhooks(authToken);
  return dispatch(webhooksLoaded(response));
};

export const addWebhook = (authToken, webhook) => async dispatch => {
  await AddWebhook(authToken, webhook);
  dispatch(webhookAdded(webhook));
};

export const deleteWebhook = (authToken, webhookId) => async dispatch => {
  await DeleteWebhook(authToken, webhookId);
  dispatch(webhookDeleted(webhookId));
};

export default webhooksSlice.reducer;
