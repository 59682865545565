import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className={"modal is-dark" + (this.props.open ? " is-active" : "")}>
            <div className="modal-background" onClick={this.closeModal} />
            <div className="modal-content">
                <div className="box has-background-dark has-text-light">
                    {this.props.children}
                </div>
            </div>
            <button className="modal-close is-large" onClick={this.props.close} aria-label="close" />
        </div>;
    }
}

export default Modal