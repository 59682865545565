import { createReducer } from "@reduxjs/toolkit"
import { AxiosError } from "axios"

export const API_OUTAGE = 'API_OUTAGE'
export const API_RESPONSE = 'API_RESPONSE'
export const API_ERROR = 'API_ERROR'

export interface ApiErrorPayload {
  errorResponse: AxiosError
}

export interface ErrorResponse {
  error: string
}

export default createReducer({
  available: 'loading',
  error: '',
}, builder => {
  builder.addCase(
    API_OUTAGE, state => {
      state.available = 'unavailable'
    }
  ).addCase(
    API_RESPONSE, state => {
      state.available = 'available'
    }
  ).addCase(
    API_ERROR, state => {
      state.available = 'available'
      state.error = 'oops'
    }
  )
})
