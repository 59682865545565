import { requestHeaders } from "./helpers";

export const GetConnections = async (authToken) => {
  try {
        const r = await fetch(process.env.REACT_APP_API_ENDPOINT + '/api/connections', {
            method: 'GET',
            mode: 'cors',
            headers: requestHeaders(authToken),
        });
        return await r.json();
    } catch (e) {
        console.error(e);
    }
};

export const AuthorizeConnection = async (authToken, service, query) => {
    let url = new URL(process.env.REACT_APP_API_ENDPOINT + '/api/connections/authorize/' + service);
    url.search = query.toString();

    try {
        return await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: requestHeaders(authToken),
        });
    } catch (e) {
        console.error(e);
    }
};
