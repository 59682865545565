import { ReactElement, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import { useDispatch } from "react-redux";
import { fetchVideo, fetchChat } from "../redux/reducers/video";
import { useAppSelector } from "../redux/hooks";
import { DateTime } from "luxon";
import { ChatMsg } from "./Vod/chat";
import { DefaultSettings, FetchFromStorage, PersistSettings, Settings } from "./Vod/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type VodParams = {
  id: string;
}

const Vods = () => {

  let dispatch = useDispatch();
  let params = useParams<VodParams>();
  let video = useAppSelector(s => s.video);
  let [scriptAdded, setScriptAdded] = useState<boolean>(false);
  let [player, setPlayer] = useState<YT.Player>();
  let [playerReady, setPlayerReady] = useState<boolean>(false);
  let [playerState, setPlayerState] = useState<number>(-1);
  let [playerTime, setPlayerTime] = useState<number>(0);
  let lastScrollHeightSeenByScroll = useRef<number>(0);
  let scrollHeightAfterRender = useRef<number>(0);
  let scrollTopAfterRender = useRef<number>(0);
  let [scrollLocked, setScrollLocked] = useState<boolean>(false);
  let [debugEnabled, enableDebug] = useState<boolean>(false);
  let [settings, setUserSettings] = useState<Settings>(DefaultSettings);
  let [showSettings, setShowSettings] = useState<boolean>(false);

  let lastShownMessageCount = useRef<number>(0);

  let id = params['id'];

  const onYouTubeIframeReady = useCallback(() => {
    const player = new YT.Player('player', {
      // height: undefined,
      // width: '100%',
      // @API
      videoId: video.youtubeId, // previous: ACUdcbXC4Zs
      playerVars: {
        'playsinline': 1,
      },
      events: {
        'onReady': () => { setPlayerReady(true); },
        'onStateChange': () => {
          setPlayerState(player.getPlayerState());
        },
      }
    });
    setPlayer(player);
  }, [video.youtubeId, setPlayerReady]);

  useEffect(() => {
    if (!player || !playerReady) return;
    if (playerState !== YT.PlayerState.PLAYING) return;
    const intervalId = setInterval(() => {
      setPlayerTime(current => {
        if (Math.abs(player.getCurrentTime() - current) > 5) {
          setScrollLocked(false);
        }
        return player.getCurrentTime()
      })
    }, 500);

    return () => {
      clearInterval(intervalId);
    }
  }, [player, playerReady, playerState, setPlayerTime, setScrollLocked]);

  // initial effect, fetch data
  useEffect(() => {
    dispatch(fetchVideo(id))
    dispatch(fetchChat(id))

    FetchFromStorage().then((settings: Settings) => {
      setUserSettings(settings);
    });

  }, [dispatch, id]);

  useEffect(() => {
    if (player && playerReady && video) {
      // @ts-ignore somehow this is not in the types
      document.title = video.streamer + ' - ' + DateTime.fromISO(video.streamStartedAt).toLocaleString(DateTime.DATE_HUGE) + ' - ' + player.videoTitle;
    }
  }, [video, player, playerReady])

  const wrap = (el: ReactNode) => {
    return <div className="is-widescreen">
      {el}
    </div>;
  }

  if (!id) {
    return wrap(Render404('no id'));
  }

  if (video.error) {
    return wrap(Render404(video.error));
  }

  if (!video.loaded) {
    return wrap(<Loading />);
  }

  // @ts-ignore
  window.onYouTubeIframeAPIReady = onYouTubeIframeReady;
  // @ts-ignore
  window.toggleDebugInfo = () => { enableDebug(d => !d) };

  if (!scriptAdded) {
    let script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag) {
      firstScriptTag.parentNode?.insertBefore(script, firstScriptTag);
    }
    setScriptAdded(true);
  }

  // Feels inefficient: every 500ms it now rerenders the whole component
  // but as it rerenders in sub milliseconds, I'm okay with that
  const renderedMessages: ReactElement[] = [];
  for (var i = 0; i < video.chat.length; i++) {
    var msg = ChatMsg.fromJson(video.chat[i]);
    var absolutePlayerTime = new Date((new Date(video.streamStartedAt)).getTime() + (playerTime * 1000))
    if (!msg.postedBefore(absolutePlayerTime)) {
      // assume a ordered list, so when it's not before we don't have to loop over the rest of the messages
      break;
    }
    renderedMessages.push(msg.render(i, video, settings));
  }

  const newMessages = renderedMessages.length - lastShownMessageCount.current;
  if (newMessages !== 0 && debugEnabled) {
    console.log('added ' + newMessages + ' messages [locked: ' + scrollLocked + ']');
  }

  lastShownMessageCount.current = renderedMessages.length;

  const onScroll = (event: SyntheticEvent) => {
    const chat = event.currentTarget;

    if (lastScrollHeightSeenByScroll.current !== chat.scrollHeight) {
      lastScrollHeightSeenByScroll.current = chat.scrollHeight;
      // content changed, we don't want update scrollLock now
      return;
    }

    const lockingScroll = chat.scrollTop !== scrollTopAfterRender.current;
    // console.log('locking scroll: ', lockingScroll);
    // setScrollLocked(lockingScroll);

    // console.log(
    //   'scrollHeight: ', chat.scrollHeight,
    //   'scrollHeight after render: ', scrollHeightAfterRender.current,
    //   'lastScrollHeightSeen: ', lastScrollHeightSeenByScroll.current,
    //   'scrollTop: ', chat.scrollTop,
    //   'scrollTop after render: ', scrollTopAfterRender.current,
    //   'lockingScroll: ', lockingScroll,
    // );
  }

  const onChatLoad = (event: SyntheticEvent) => {
    const chat = event.currentTarget;
    if (debugEnabled) {
      console.log('rendered chat, scroll to bottom: ', !scrollLocked, chat.scrollTop, chat.scrollHeight);
    }
    if (!scrollLocked) {
      chat.scrollTop = chat.scrollHeight;
    }
    scrollTopAfterRender.current = chat.scrollTop;
    scrollHeightAfterRender.current = chat.scrollHeight;
  };

  const Debug = () => {
    if (!debugEnabled) return <></>;

    const B = (v: boolean) => {
      const text = v ? "true" : "false"
      return <span className={"has-text-weight-bold has-text-" + (v ? "success" : "danger")}>{text}</span>
    }

    return <table className="table">
      <thead>
        <tr><th colSpan={2}>Debug</th></tr>
      </thead>
      <tbody>
        <tr>
          <td>Scroll Lock</td><td>{B(scrollLocked)}</td>
        </tr>
        <tr>
          <td>Playback time</td><td>{playerTime}</td>
        </tr>
        <tr className="is-hidden-tablet"><td>Mobile</td><td>True</td></tr>
        <tr className="is-hidden-desktop is-hidden-mobile"><td>Tablet</td><td>True</td></tr>
        <tr className="is-hidden-touch"><td>Desktop</td><td>True</td></tr>
      </tbody>
    </table>;
  }

  const streamDate = DateTime.fromISO(video.streamStartedAt);

  return wrap(
    <>
      <div className="vod-wrapper">
        <div className="has-video">
          <div id="player"></div>
          <div>
            <span className="is-size-1">{video.streamer}</span>
            <span className="is-size-3"> - {streamDate.toLocaleString(DateTime.DATE_HUGE)} 🎥</span>
            <span className="is-pulled-right">
              <span className="icon is-large is-clickable" onClick={() => { setShowSettings(true); }}>
                <FontAwesomeIcon icon="gear" />
              </span>
            </span>
          </div>
          <Debug />
          <SettingsModal active={showSettings} settings={settings} onSave={(settings: Settings) => { setUserSettings(settings); }} onCancel={() => {setShowSettings(false)}} persist={() => {PersistSettings(settings);setShowSettings(false);}} />
        </div>
        <div className="has-chat is-overflow-y" onLoad={onChatLoad} onScroll={onScroll}>
          <div id="chat" className={"is-flex-direction-column is-justify-content-flex-end" + (scrollLocked ? " with-scrollbar" : "")}>
            <div className="chat-msg has-text-grey">Welcome to chat</div>
            {renderedMessages.slice(-100)}
          </div>
        </div>
      </div>
    </>);
};

const SettingsModal = (props: {active: boolean, settings: Settings, onSave: (settings: Settings) => void, onCancel: () => void, persist: () => void}) => {
  const classNames = ["modal"];
  if (props.active) {
    classNames.push('is-active');
  }

  const settings = props.settings;

  return <div className={classNames.join(' ')}>
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Settings</p>
        <button className="delete" aria-label="close" onClick={props.onCancel}></button>
      </header>
      <section className="modal-card-body">
        <form id="user_settings_form">
          <div className="field is-horizontal">
            <div className="field-label is-normal">Show timestamps</div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input name="showTimestamps" type="checkbox" checked={props.settings.showTimestamps} onChange={() => { props.onSave({
                    showTimestamps: !settings.showTimestamps,
                    relativeTimestamps: settings.relativeTimestamps,
                  }) }} />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">Relative timestamps</div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input name="relativeTimestamps" type="checkbox" checked={props.settings.relativeTimestamps} onChange={() => { props.onSave({
                    showTimestamps: settings.showTimestamps,
                    relativeTimestamps: !settings.relativeTimestamps,
                  }) }} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <footer className="modal-card-foot">
        <button type="submit" className="button primary" onClick={props.persist}>Save</button>
        <button className="button" onClick={props.onCancel}>Cancel</button>
      </footer>
    </div>
  </div>;
};

const Render404 = (error?: string) => {
  return <p>Oops: {error}</p>
};

export default Vods;
