import React from 'react';
import ReactDOM from 'react-dom';
import './overrides.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider } from './auth/AuthProvider';
import reportWebVitals from './reportWebVitals';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Vods from './modules/Vods';

const history = createBrowserHistory();

let isEnvValid = (): boolean => {
  if (
    process.env.REACT_APP_API_ENDPOINT === '' ||
    process.env.REACT_APP_AUTHZERO_AUDIENCE === '' ||
    process.env.REACT_APP_AUTHZERO_CLIENT_ID === '' ||
    process.env.REACT_APP_AUTHZERO_DOMAIN === ''
  ) {
      return false;
  }

  return true;
};

if (!isEnvValid()) {
    var h1 = document.createElement('h1');
    h1.style.textAlign = 'center';
    h1.style.fontSize = '3em';
    h1.style.color = 'white';
    h1.innerText = 'Everything is broken 😱';
    document.getElementById('root')?.append(h1);
} else {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <Router history={history}>
                  <Switch>
                      <Route path="/vods/:id">
                        <Vods />
                      </Route>
                      <Route path="/dashboard">
                        <AuthProvider history={history}>
                            <App />
                        </AuthProvider>
                      </Route>
                      <Route path="*" exact>
                        404?
                      </Route>
                    </Switch>
                </Router>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
