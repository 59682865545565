import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit';
import { API_CALL } from '../middleware/api';

interface BadgeVersion {
  id: string,
  title: string,
  image_url_1x: string,
  image_url_2x: string,
  image_url_4x: string,
}

interface BadgeSet {
  set_id: string,
  versions: { [version: string]: BadgeVersion }
}

export type BadgeSets = {
  [set_id: string]: BadgeSet,
}

export type Video = {
  streamer: string;
  youtubeId: string;
  streamStartedAt: string,
  badgeSets: BadgeSets,
  chat: JsonChatMsg[],
  bttv: Bttv,
  loaded: boolean,
  error?: string,
};

type Bttv = {
  channelEmotes: BttvEmote[],
  sharedEmotes: BttvEmote[],
};

export type BttvEmote = {
  id: string,
  code: string,
};

const videoSlice = createSlice<Video, SliceCaseReducers<Video>, string>({
  name: 'video',
  initialState: {
    streamer: '',
    youtubeId: '',
    streamStartedAt: '',
    badgeSets: {},
    chat: [],
    bttv: {channelEmotes: [], sharedEmotes: []},
    loaded: false,
    error: undefined
  },
  reducers: {
    VideoLoading() { },
    VideoLoaded(state, response) {
      state.loaded = true;
      state.streamer = response.payload.data.streamer;
      state.youtubeId = response.payload.data.youtubeId;
      state.streamStartedAt = response.payload.data.streamStartedAt;
      state.bttv.channelEmotes = response.payload.data.bttv.channelEmotes;
      state.bttv.sharedEmotes = response.payload.data.bttv.sharedEmotes;

      if (response.payload.data.channelBadges !== null) {
        response.payload.data.channelBadges.forEach((set: any) => {
          if (state.badgeSets.hasOwnProperty(set.set_id)) {
            // don't allow global sets to override channel sets
            return;
          }
          state.badgeSets['' + set.set_id] = {
            set_id: set.set_id,
            versions: {}
          }
          set.versions.forEach((version: BadgeVersion) => {
            state.badgeSets['' + set.set_id].versions[version.id] = version;
          });
        })
      }
    },
    VideoError(state, error) {
      state.error = error.payload.message;
    },
    ChatLoaded(state, response) {
      const messages = response.payload.data // .map((msg: JsonChatMsg) => ChatMsg.fromJson(msg))
      state.chat = messages;
    }
  },
});

export const fetchVideo = (id: string) => {
  return {
    [API_CALL]: {
      request: {
        endpoint: "/api/vod/" + id,
      },
      types: [VideoLoading, VideoLoaded, VideoError],
      authenticated: false,
    }
  }
}

export const fetchChat = (id: string) => {
  return {
    [API_CALL]: {
      request: {
        endpoint: "/api/vod/" + id + "/chat.json",
      },
      types: [VideoLoading, ChatLoaded, VideoError],
      authenticated: false,
    }
  }
}

export const { VideoLoading, VideoLoaded, VideoError, ChatLoaded } = videoSlice.actions;

export default videoSlice.reducer;

export interface JsonChatMsg {
  type: string;
  datetime: string;
  username: string;
  display_name: string;
  message: string;
  color: string;
  emotes: string;
  badges: string;
  system_message: string|undefined;
}
