import { configureStore } from '@reduxjs/toolkit';
import ApiMiddleware from './middleware/api';
import reducers from './reducers';

const store = configureStore({
  reducer: reducers,
  middleware(getDefaultMiddleware) {
      return getDefaultMiddleware().concat(ApiMiddleware)
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// temporary until everything is TSX
export default store
