export type Settings = {
  showTimestamps: boolean,
  relativeTimestamps: boolean;
}

export const DefaultSettings: Settings = {
  showTimestamps: true,
  relativeTimestamps: true,
};

export async function FetchFromStorage(): Promise<Settings> {
  return new Promise<Settings>(function (resolve: (value: Settings) => void, reject) {
    const fromStorage = localStorage.getItem('vods.user-settings');
    if (fromStorage === null) {
      resolve(DefaultSettings);
      return;
    }

    resolve(JSON.parse(fromStorage));
  });
}

export function PersistSettings(settings: Settings): void {
  localStorage.setItem('vods.user-settings', JSON.stringify(settings));
}
