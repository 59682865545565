import { PayloadAction, SliceCaseReducers, createSlice } from '@reduxjs/toolkit';
import { API_CALL } from '../middleware/api';
import axios, { AxiosError } from 'axios';

type SonglistSlice = {
  songs: any[]
  status: string
  error?: string
}

const songlistSlice = createSlice<SonglistSlice, SliceCaseReducers<SonglistSlice>, string>({
    name: 'songs',
    initialState: {
        songs: [],
        status: 'idle',
        error: undefined
    },
    reducers: {
        songsLoading(state) {
            state.status = 'loading';
        },
        songsLoaded(state, action) {
            state.songs = action.payload;
            state.status = 'idle';
        },
        songsError(state, error: PayloadAction<AxiosError>) {
          if (error.payload.response?.status === axios.HttpStatusCode.PreconditionFailed) {
            state.status = 'undefined'
            return
          }
          state.status = 'errored';
          state.error = error.payload.message;
        }
    }
});

export const { songsLoading, songsLoaded, songsError } = songlistSlice.actions;

export const fetchSongs = () => {
  return {
    [API_CALL]: {
      request: { endpoint: '/api/songlist/default/songs' },
      handledErrors: [axios.HttpStatusCode.PreconditionFailed],
      types: [songsLoading, songsLoaded, songsError],
    }
  }
};

//export const addSong = (songlist, song) => dispatch => {
//};

export default songlistSlice.reducer;
