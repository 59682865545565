import { PropsWithChildren } from "react";
import { useAppSelector } from "../redux/hooks";
import { AppNotification, NotificationType, RemoveNotification, getNotifications } from "../redux/reducers/notifications";
import { useDispatch } from "react-redux";

type NotificationProps = PropsWithChildren & {
  identifier: any
  type: NotificationType
}

export function Notification(props: NotificationProps) {
    const dispatch = useDispatch();
    const setHidden = () => {
      dispatch(RemoveNotification(props.identifier))
    }

    return (
        <div className={"notification" + " is-"+(props.type.toString() ?? 'info')}>
            <button className={"delete"} onClick={setHidden}></button>
            {props.children}
        </div>
    );
}

const Notifications = () => {
  const notifications: AppNotification[] = useAppSelector(getNotifications)
  if (notifications.length === 0) {
    return <></>
  }
  return <div className="notifications py-2 px-1">{notifications.map((notification, index) => {
    return <Notification type={notification.type} key={"n"+index} identifier={index}>
      <strong>{notification.title}</strong>
      <p>{notification.description}</p>
    </Notification>
  })}</div>
}

export { Notifications }
