import React from 'react';
import { connect } from 'react-redux';
import {deleteWebhook, fetchWebhooks} from '../redux/reducers/webhooks';
import { syncUser } from '../redux/reducers/auth';
import { withAuth0 } from '@auth0/auth0-react';
import AddWebhookForm from './Webhooks/Add.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Webhooks extends React.Component {
  constructor(props) {
    super(props);
    this.props.auth0.getAccessTokenSilently().then(t => this.onAuthenticated(t));
    this.state = { showForm: false };

    this.addWebhook = this.addWebhook.bind(this);
    this.deleteWebhook = this.deleteWebhook.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.refreshWebhooks = this.refreshWebhooks.bind(this);
  }

  onAuthenticated(token) {
    this.token = token;
    this.props.dispatch(syncUser(this.props.auth0.user, this.token));
    this.props.dispatch(fetchWebhooks(this.token));
  }

  addWebhook() {
    this.setState(state => ({ showForm: true}));
  }

  deleteWebhook(webhookId) {
    this.props.dispatch(deleteWebhook(this.token, webhookId));
  }

  closeModal() {
    this.setState(state => ({ showForm: false }));
  }

  refreshWebhooks() {
    if (this.token !== undefined) {
      this.props.dispatch(fetchWebhooks(this.token));
    }
  }

  render() {
    if (this.props.auth.user === null) {
      return <div>Checking permissions</div>
    }

    if (this.props.auth.user.roles[0] !== 'admin') {
      return <div>Unauthorized</div>
    }

    return (
      <div>
        <h1 className="is-size-1">Twitch Webhooks</h1>
        <div className="block">
          <button className="button is-primary" onClick={this.addWebhook}>Add webhook</button>
          <button className="button ml-2 is-dark">
            { this.props.webhooks.status === "loading" ? <FontAwesomeIcon icon="spinner" spinning="true" /> : <FontAwesomeIcon icon="sync" inverse={true}  onClick={this.refreshWebhooks} /> }
          </button>
        </div>
        <table className="table is-bordered is-striped">
          <thead>
            <tr>
              <th>Hook</th>
              <th>Broadcaster</th>
              <th>Status</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            { this.props.webhooks.webhooks && this.props.webhooks.webhooks.length
                ? this.props.webhooks.webhooks.map((webhook, index) => {
                  return <tr key={"webhook_" + index }>
                      <td>{ webhook.hook }</td>
                      <td>{ webhook.broadcaster }</td>
                      <td>{ webhook.status }</td>
                      <td className={"has-text-centered"}>
                        <button className={"button is-danger"} onClick={(x) => { this.deleteWebhook(webhook.id) } }>
                          <FontAwesomeIcon icon="trash-alt" />
                        </button>
                      </td>
                    </tr>
                  ;
                })
                : (
                  this.props.webhooks.status === 'loading' ?
                  (
                    <tr><td colSpan="4" align="center">Loading...</td></tr>
                  ) :
                  (
                    <tr><td colSpan="4">No webhooks</td></tr>
                  )
                )
            }
          </tbody>
        </table>
        <div className={"modal" + (this.state.showForm ? " is-active" : "")}>
          <div className="modal-background" onClick={this.closeModal}></div>
          <div className="modal-content">
            <div className="box">
              <AddWebhookForm close={this.closeModal} />
            </div>
          </div>
          <button className="modal-close is-large" onClick={this.closeModal} aria-label="close"></button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { webhooks: state.webhooks, auth: state.auth };
};

export default withAuth0(connect(mapStateToProps)(Webhooks));
