export const SongsTable = props => {
    return <table className={"table is-dark"}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Artist/Origin</th>
          </tr>
        </thead>
        <tbody>
        { props.songs.map((song, index) => {
            return <tr key={"song_" + index}>
                <td>{song.title}</td>
                <td>{song.origin}</td>
            </tr>;
        }) }
        </tbody>
    </table>
};
