import { createSlice } from '@reduxjs/toolkit';
import { GetSettings, UpdateSetting } from "../../../api/user/settings";

const settingsSlice = createSlice({
  name: 'user/settings',
  initialState: {
    settings: {},
    status: 'idle',
    error: null
  },
  reducers: {
    loading(state) {
      if (state.status === 'idle') {
        state.status = 'loading';
      }
    },
    loaded(state, action) {
      if (state.status === 'loading') {
        state.status = 'idle';
        state.settings = action.payload;
      }
    },
    settingUpdated(state, action) {
        state.status = 'idle';
        state.settings[action.payload.field] = action.payload.value;
    },
  }
});

export const { loaded, loading, settingUpdated } = settingsSlice.actions;

export const fetchSettings = (authToken) => async dispatch => {
  dispatch(loading());
  const settings = await GetSettings(authToken);
  return dispatch(loaded(settings));
};

export const updateSetting = (authToken, field, value) => async dispatch => {
    dispatch(loading());
    await UpdateSetting(authToken, field, value);
    return dispatch(settingUpdated({field: field, value: value}));
};

export default settingsSlice.reducer;

