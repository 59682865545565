import React from 'react'
import { withRouter } from 'react-router';

class Breadcrumb extends React.Component
{
  getCurrent() {
    const match = this.props.match;
    const map = {
      '/user/settings': 'User Settings',
    };

    if (map.hasOwnProperty(match.path)) {
      return <li className="is-active"><a href={match.path}>{map[match.path]}</a></li>
    }
  }

  render() {
    return <nav className="breadcrumb mt-5">
      <ul>
        <li><a href="/">Home</a></li>
        {this.getCurrent()}
      </ul>
      </nav>
  }
}

export default withRouter(Breadcrumb);
