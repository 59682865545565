import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useLocation, useParams} from "react-router-dom/cjs/react-router-dom";
import React, {useState} from "react";
import {Notification} from "../Notification";
import {authorizeConnection} from "../../redux/reducers/connections";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OauthRedirect() {
    const history = useHistory();
    const {service} = useParams();
    const params = useQuery();
    const dispatch = useDispatch();
    const {getAccessTokenSilently} = useAuth0();
    const [status, setStatus] = useState({processing: true, successful: false});

    if (params.get('error')) {
        return <Notification type="danger">😭 Failed to connect to {service}: {params.get('error_description')}</Notification>
    }

    if (!params.get('code')) {
        return <Notification type="danger">Does not appear to be a redirect, try again</Notification>
    }

    getAccessTokenSilently().then(async token => {
        dispatch(authorizeConnection(token, service, params))
            .then(_ => setStatus({procssing: false, successful: true}))
            .catch(_ => setStatus({procssing: false, successful: false}))

    });

    if (status.processing) {
        return <Notification type="info">Processing connection for {service}!</Notification>
    }

    if (!status.successful) {
        return <Notification type="danger">😭 Failed to authorize {service}, try again.</Notification>
    }

    history.push('/connections');
    return <Notification type="success">Authorized {service}!</Notification>
}
