import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = () => {
    return (<div>
        Loading...
        <FontAwesomeIcon icon="spinner" pulse={true} />
    </div>
    );
};

export default Loading