import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import './App.scss';
import './Icons.js';
import Breadcrumb from './Breadcrumb';
import {Dashboard} from './modules/Dashboard';
import Webhooks from './modules/Webhooks';
import {Connections} from './modules/Connections';
import Songlist from './modules/Songlist';
import User from './modules/User';
import UserSettings from './modules/User/Settings';
import {
  Route,
  Link,
  NavLink,
  useRouteMatch
} from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { failedToSyncUser, userSynced, syncing } from './redux/reducers/auth';
import Page404 from './Page404';
import { API_CALL } from './redux/middleware/api';
import Loading from './Loading';
import { useAppSelector } from './redux/hooks';
import { Notifications } from './modules/Notification';

const AnyWebhooks = Webhooks as any
const AnyConnections = Connections as any
const AnyUserSettings = UserSettings as any
const AnyUser = User as any

const App = () => {
  const [hasToken, setHasToken] = useState(false)
  const dispatch = useDispatch()
  const { buildLogoutUrl, getAccessTokenSilently, user } = useAuth0();
  const apiState = useAppSelector(state => state.api.available)

  const { path, url } = useRouteMatch();

  useEffect(() => {
    getAccessTokenSilently().then((token: string) => {
      localStorage.setItem('auth_token', token)
      dispatch({
        [API_CALL]: {
          request: {
            endpoint: "/api/user",
            method: "PATCH",
            body: JSON.stringify(user)
          },
          types: [syncing, userSynced, failedToSyncUser ],
          authenticated: true
        }
      });
      setHasToken(true)
    })
  }, [getAccessTokenSilently, dispatch, user])


  const renderMainContent = () => {
    
    if (!hasToken) {
      return <p>Verifying token</p>
    }

    switch (apiState) {
    case 'unavailable':
      return <><h1>Error</h1><p>API is unavailable 😥</p></>
    case 'loading':
      return <Loading />
    default:
              return <>
                <Route path={url} exact>
                  <Breadcrumb />
                  <div className="content">
                    <Dashboard />
                  </div>
                </Route>
                <Route path={`${path}/songlist`} exact>
                  <Songlist />
                </Route>
                <Route path={`${path}/webhooks`}>
                  <AnyWebhooks user={user} />
                </Route>
                <Route path={`${path}/connections`}>
                  <AnyConnections user={user} />
                </Route>
                <Route path={`${path}/user/settings`}>
                  <Breadcrumb />
                  <div className="content">
                    <AnyUserSettings user={user} />
                  </div>
                </Route>
                <Route path={`${path}/user`}>
                    <AnyUser user={user} />
                </Route>
              </>
    }
  }

  const renderAdminMenu = () => {
    if (user?.roles === undefined || user?.roles[0] !== 'admin') {
      return <></>
    }

    return <>
        <p className="menu-label">
          Admin
        </p>
        <ul className="menu-list">
          <li><Link to={`${url}/webhooks`} className="is-dark">Webhooks</Link></li>
        </ul>
      </>
  }


  return <div id="main" className="columns">
        <article className="column">
          <div className="block">
            <Notifications />
            {renderMainContent()}
          </div>
        </article>
        <aside className="menu column is-2 p-5">
          <header className="navbar">
            <div className="navbar-brand is-align-items-baseline">
              <p className="title is-size-1">Veld</p>
              <p className="title is-size-5">services</p>
            </div>
          </header>
          <nav className="">
            <p className="menu-label">Navigation</p>
            <ul className="menu-list">
              <li><NavLink to={url} exact className="is-dark" activeClassName="is-active">Dashboard</NavLink></li>
              <li><NavLink to={`${url}/songlist`} className="is-dark" activeClassName="is-active">Songlist</NavLink></li>
              <li><NavLink to={`${url}/connections`} className="is-dark" activeClassName="is-active">Connections</NavLink></li>
              <li><a href={ buildLogoutUrl() } className="is-dark">Logout</a></li>
            </ul>
            <p className="menu-label">User</p>
            <ul className="menu-list">
              <li><NavLink to={`${url}/user/settings`} className="is-dark" activeClassName="is-active">Settings</NavLink></li>
            </ul>
            { renderAdminMenu() }
          </nav>
        </aside>
      </div>
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => (<div>Logging in...</div>)
});
