import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {Connection} from "./Connections/Connection";
import {useEffect} from "react";
import {fetchConnections} from "../redux/reducers/connections";
import {Switch,Route} from 'react-router-dom';
import OauthRedirect from "./Connections/OauthRedirect";

export function Connections() {
    const dispatch = useDispatch();
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    const slice = useSelector((state) => state.connections);

    useEffect(async () => {
        const token = await getAccessTokenSilently();
        dispatch(fetchConnections(token));
     }, [dispatch, getAccessTokenSilently]);

    return isAuthenticated && (<>
        <h1 className={"is-size-1"}>Connections</h1>
        <Switch>
            <Route path="/connections/redirect/:service">
                <OauthRedirect />
            </Route>
        </Switch>
        {slice.connections.map(c => {
            return <Connection key={"conn-" + c.service} connection={c} />;
        })}
    </>)
}

/*
import React from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';
import {fetchConnections, authorizeConnection} from "../redux/reducers/connections";
import Connection from "./Connections/Connection";

class Connections extends React.Component {
  constructor(props) {
    super(props);
    this.props.auth0.getAccessTokenSilently().then(t => this.onToken(t));
  }

  onToken(t) {
    let params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      this.props.dispatch(authorizeConnection(t, params)).then(() => {
        this.props.dispatch(fetchConnections(t));
      });
      this.props.history.push('/connections');
    }
    this.props.dispatch(fetchConnections(t));
  }

  render() {
    if (this.props.connections.status === 'loading') {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <h1 className={"is-size-1"}>Connections</h1>
        { this.props.connections.connections && this.props.connections.connections.length ?
          this.props.connections.connections.map((connection, index) => {
            return <Connection key={"connection_" + index} connection={connection} />
          }) : <div>No connections</div>
        }
      </div>
    )
  }
}


const mapStateToProps = state => {
  const { connections } = state
  return { connections };
};

export default withAuth0(connect(mapStateToProps)(Connections));
*/
