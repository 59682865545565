import React from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';
import { addWebhook, fetchWebhooks } from '../../redux/reducers/webhooks';

class AddWebhookForm extends React.Component {
  constructor(props) {
    super(props);
    this.hookOptions = [
      { id: "stream.online", label: "Stream online" },
      { id: "stream.offline", label: "Stream offline" },
    ];
    this.state = {
      token: '',
      isSaving: false,
      waitingForToken: true,
      hook: this.hookOptions[0].id,
      broadcaster: '',
    };

    this.props.auth0.getAccessTokenSilently().then(t => {
      this.state.waitingForToken = false;
      this.state.token = t;
    });

    this.addWebhook = this.addWebhook.bind(this);
    this.handleBroadcaster = this.handleBroadcaster.bind(this);
    this.handleHook = this.handleHook.bind(this);
  }

  addWebhook(e) {
    e.preventDefault();
    this.setState({isSaving: true});
    this.props.dispatch(addWebhook(this.state.token, { hook: this.state.hook, broadcaster: this.state.broadcaster })).then(() => {
      this.setState({isSaving: false});
      this.props.dispatch(fetchWebhooks(this.state.token));
      this.props.close();
    });
  }

  handleBroadcaster(e) {
    this.setState({broadcaster: e.target.value });
  }

  handleHook(e) {
    this.setState({hook: e.target.value });
  }

  render() {
    return <form>
      <h3 className="is-size-3">Add webhook</h3>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Broadcaster</label>
        </div>
        <div className="field-body">
          <div className="control">
            <input className={"input"} type={"text"} placeholder={"Twitch username"} onChange={this.handleBroadcaster} />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Hook</label>
        </div>
        <div className="field-body">
          <div className="control">
            <div className="select">
              <select value={this.state.hook} onChange={this.handleHook}>
                {this.hookOptions.map(option => {
                  return <option value={option.id} key={option.id}>{option.label}</option>
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label" />
        <div className="field-body">
          <button className={"button is-primary" + (this.state.isSaving ? " is-loading" : "")} onClick={this.addWebhook}>Add webhook</button>
        </div>
      </div>
    </form>
  }
}

export default withAuth0(connect()(AddWebhookForm))
