import { requestHeaders } from "../helpers";

export const GetSettings = (authToken) => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/api/user/settings', {
    method: 'GET',
    mode: 'cors',
    headers: requestHeaders(authToken),
  })
    .then(r => r.json())
    .then(settings => {
      return settings;
    })
    .catch(e => {
      console.error(e);
      return {};
    });
}

export const UpdateSetting = (authToken, field, value) => {
    return fetch(process.env.REACT_APP_API_ENDPOINT + '/api/user/settings', {
        method: 'PATCH',
        mode: 'cors',
        headers: requestHeaders(authToken),
        body: JSON.stringify({field: field, value: value}),
    })
    .catch(e => {
        console.error(e);
    })
};
