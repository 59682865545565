import {React, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {fetchSettings, updateSetting} from "../../redux/reducers/user/settings";
import {Notification} from "../Notification";

const UserSettings = () => {
    const dispatch = useDispatch();
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const settingsState = useSelector(state => state.settings);

    const [token, setToken] = useState('')

    useEffect(async () => {
        // get settings
        const token = await getAccessTokenSilently();
        setToken(token);
        dispatch(fetchSettings(token));
    }, [getAccessTokenSilently, dispatch]);

    return isAuthenticated && (
        <div>
            <h1 className={"is-size-1"}>User settings</h1>
            {settingsState.settings ? <SettingsForm settingsState={settingsState} token={token} /> : <p className="error">No setting :( </p>}
        </div>
    );
}

const SettingsForm = (args) => {
    const dispatch = useDispatch();
    const [saved, setSaved] = useState(false);

    const markSaved = () => {
        setSaved(true);
        window.setTimeout(() => {
            setSaved(false);
        }, 2000);
    };

    const settingChanged = (field) => {
        return async (e) => {
            dispatch(updateSetting(token, field, e.target.value));
            markSaved()
        }
    };
    const token = args.token;
    let settings = args.settingsState.settings;
    return <form>
        {saved ? <Notification type="primary"><p>Settings updated</p></Notification> : ''}
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label">Songlist mode</label>
            </div>
            <div className="field-body">
                <div className="control">
                    <div className="select">
                        <select value={settings.songlist_mode} onChange={settingChanged('songlist_mode')} defaultValue="proxy_streamersonglist">
                            <option value="standalone">Standalone (pre-alpha)</option>
                            <option value="proxy_streamersonglist">Proxy streamersonglist</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        </form>;
}

export default UserSettings;
