import axios, { AxiosError, AxiosHeaders } from "axios"
import { Middleware } from "redux"
import { API_ERROR, API_OUTAGE, API_RESPONSE } from "../reducers/api"

export const API_CALL = Symbol("API Call")

const ApiMiddleware: Middleware = _ => next => action => {
  const apiCall = action[API_CALL]

  if (typeof apiCall === 'undefined') {
    return next(action)
  }

  const { request, types, authenticated } = apiCall
  let { handledErrors } = apiCall
  const { endpoint, method, body } = request
  const [ requestType, successType, errorType ] = types

  handledErrors = handledErrors ||  []

  next(requestType())

  let headers = new AxiosHeaders();
  if (authenticated !== false) {
    const authToken = localStorage.getItem('auth_token') || null;
    if (authToken === null) {
      throw new Error("authenticated request failed, no token available");
    }
    headers.setAuthorization('Bearer ' + authToken);
  }

  return axios(process.env.REACT_APP_API_ENDPOINT + endpoint, {
    method: method || 'GET',
    headers,
    data: body,
  })
  .catch(error => Promise.reject(error))
  .then(
    successResponse => {
        next({ type: API_RESPONSE })
        return next(successType(successResponse))
      },
    (errorResponse: AxiosError) => {
      if (errorResponse.code === 'ERR_NETWORK') {
        next({ type: API_OUTAGE })
        return
      }
      if (handledErrors.indexOf(errorResponse.response?.status) === -1) {
        next({ payload: { errorResponse, authenticated }, type: API_ERROR })
      }
      return next(errorType(errorResponse))
    }
  );
}

export default ApiMiddleware
